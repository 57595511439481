@import '../less-modules/ca-font';
@import '../less-modules/variables';

.error-404 {
  background: @color-light;
  bottom: 0;
  left: 0;
  opacity: 1;
  position: fixed;
  right: 0;
  top: 0;
  text-align: center;
  z-index: 100;

  @media (min-height: 600px) {
    background: @color-light url(../images/rip-ie/ross.png) no-repeat center bottom;
  }

  &_content {
    .verticalAlignAbsolute();
    left: 0;
    right: 0;

    @media (min-height: 600px) {
      .verticalAlignAbsolute(10%);
    }

    @media (min-height: 750px) {
      .verticalAlignAbsolute(30%);
    }
  }

  &_header,
  &_subheader,
  &_text {
    line-height: 1em;
  }

  &_header {
    font-size: 200px;
    font-weight: 800;
  }

  &_subheader {
    font-size: @size-h2;
    font-weight: normal;
    margin-bottom: @default-spacing;
  }

  &_link {
    color: @blue-3;
    cursor: pointer;

    &:hover {
      color: @blue-3;
      opacity: .7;
    }
  }
}


.rip-ie {
  background: #b1cfdd;
  bottom: 0;
  left: 0;
  opacity: 1;
  position: fixed;
  right: 0;
  top: 0;
  z-index: 100;
  .transition(opacity .3s ease-in-out);

  &.is-visible { 
    opacity: 1;
  }

  &_top {
    position: relative;
    height: 50%; 
  }

  &_rain {
    background: transparent url(../images/rip-ie/rain.png) repeat-x center bottom;
    background-size: cover;
    bottom: 20px;
    left: 0;
    position: absolute;
    top: 0;
    right: 0;
    z-index: 10;
  }

  &_graphic {
    bottom: 20px;
    position: absolute;
    left: 0;
    margin: 0 auto;
    right: 0;
    width: 75%;
    z-index: 5;

    @media (min-width: 775px) {
      width: auto;
    }
  }

  &_bottom {
    background: #764c29;
    height: 50%;
  }

  &_grass-1 {
    bottom: 0;
    background: #2bb673;
    height: 65px;
    left: 0;
    right: 0;
    position: absolute;
  }

  &_grass-2 {
    background: #24a166;
    height: 10px;
  }

  &_dirt-1 {
    background: #a97b50;
    height: 10px;
  }

  &_dirt-2 {
    background: #8b5e3b;
    position: relative;
    padding: 20px;
  }

  &_dirt-3 {
    padding-top: 20px;
    text-align: center;
  }

  &_message {
    color: @color-light;
    font-size: @size-h5;
    margin: 0 auto;
    text-align: center;
    max-width: 575px; 

    @media (min-width: 775px) {
      font-size: @size-h4; 
      width: 700px;
    }
  }

  &_browser {
    display: inline-block;
    color: @color-light;
    font-size: @size-p;
    margin: 0 15px;a
    text-align: center;
    width: 60px;

    &:hover {
      color: @color-light;
      opacity: .7;
    }

    @media (min-width: 775px) {
      font-size: @size-h4;
      width: 100px;
    }

    &-img {
      width: 100%;
    }
  } 
}