@import '-auto';

//@import url("https://use.typekit.net/dbg6jht.css");

@quiz-border-width: 2px; 
@quiz-font-size: @size-p;
@quiz-widget-margin-top: @default-spacing * 3;
@quiz-widget-margin-bottom: 36px;
@quiz-widget-margin: 0 0 @quiz-widget-margin-top 0;

@incorrect-color:#CD5C31;
@correct-color: #31CD4B; 

@correct-hint: 0 0 0 @quiz-border-width @correct-color;
@incorrect-hint: 0 0 0 @quiz-border-width @incorrect-color;

.correct-hint-inset() {
  box-shadow: inset @correct-hint !important;
}

.incorrect-hint-inset() {
  box-shadow: inset @incorrect-hint;
}

.correct-hint() {
  box-shadow: @correct-hint; 
}

.incorrect-hint() {
  box-shadow: @incorrect-hint;
}

.quiz-hints() {
  transition: box-shadow .3s;

  &.is-incorrect {
    .incorrect-hint();
  }

  &.is-correct {
    .correct-hint();
  }
}

/*Used with fontSpy*/
@font-museo-only: 'museo-sans';
@font-montserrat-only: 'Montserrat';

@font-museo: @font-museo-only, sans-serif;
@font-normal: @font-museo;
/*@font-open-sans: 'Open Sans', sans-serif;/*/
@font-montserrat: @font-montserrat-only, sans-serif;
@font-clicker-script: 'Clicker Script', cursive;
@font-icons: 'codeavengers-font';
@font-code: 'Monaco', 'Menlo', 'Ubuntu Mono', 'Consolas', 'source-code-pro', monospace;

@size-code-blocks: 13px;
@size-code-block: .9em;
@size-code-inline: .95em;

@resource-titlebar-height: 48px; 

/*font sizes*/
@size-h1: 42px;
@size-h2: 36px;
@size-h3: 30px;
@size-h4: 24px;
@size-h5: 20px;
@size-p: 15px;
@size-small: 14px;
@size-h6: 12px;
@size-button: 11px;
@size-x-small: @size-button;

/*--- colors ---*/
@lesson-highlight: #17a0e1;
@lesson-background: #1e1f2a;
@lesson-background-light: lighten(@lesson-background, 15%);

@container-background: #f1f2f7;
@popup-background: rgba(30, 31, 42, .9);
@lesson-color: #dfe0e3;
@box-shadow-color:rgba(0,0,0,.39);

@beta-color: #58585a;

@edu-color-1: #DAAF58;
@edu-color-2: #C19F64;
@edu-color-3: #efb257;
@edu-color-4: #B87029;

@pro-color-1: #88B3B7;
@pro-color-2: #427d7f;
@pro-color-3: #1F6366;
@pro-color-4: #247F80;

@jr-color-1: #A0B762;
@jr-color-2: #84975b;
@jr-color-3: #223A1F;
@jr-color-4: #487B3A; 

@white: #fff; 
@black: #000;
@error-color: #1a80c7;
@light-grey: #f2f2f2;
@medium-grey: #878787;
@blue: #2f9de6;
@dark-grey: #2f3241;
@red: #BF3C27;
@green: #3ec798;
@purple: #716bf1;

@blue-1: #5b9ad3;
@blue-2: #006fa8;
@blue-3: #106fa8;

@edu: #6dc174;
@pro: #40b4e7;
@jr: #f89e49;

@gloss: #A4E5FD;

@other: #be654a;

@grey-one: #F1F2F2;
@grey-two: #e4e6e9;
@grey-three: #d2d3d8;
@grey-four: #c7c7ca;
@grey-five: #808080;

@color-light: @white;
@color-medium: @medium-grey;
@color-dark: @black;

@cta-button: @red;
@cta-button-hover: darken(@red, 10%);

@quiz-primary: #3084d8;
@quiz-correct: #29cb57;
@quiz-wrong: #7b8093;
@quiz-selecting: #ff7e40;

@quiz-primary-dark: darken(@quiz-primary, 15%);
@quiz-primary-light: darken(@quiz-primary, 15%);
@quiz-selecting-dark: darken(@quiz-selecting, 15%);

@primary-button: #565876;

@color-points: rgba(60, 60, 60, 0.8);
@color-badges: rgba(60, 60, 60, 0.8);

@color-twitter: #55acef;
@color-facebook: #3b5999;

@course-intro: #7d8896;
@course-web-dev: #22b7ab;
@course-javascript: #d5a42f;
@course-html-css: #ab6646;
@course-python: #247f86;
@course-jquery: @course-javascript;
@course-design: #86582e;
@course-comp-sci: #87b3b7;
@course-algorithms: @course-comp-sci;
@course-olympics: #a0b87e;
@course-promotion: #c56b52;
@course-digital-systems: #3f5e34;
@course-digital-infrastructure: #b2cc66;
@course-digital-media: #c56b52;
@course-data-representation: #7D6278;
@course-programming: #BE654A;
@course-computational-thinking: #DAAF58;
@course-pd: #665263;
@course-impacts-of-computing: @course-web-dev;
@course-networks-and-security: @course-digital-infrastructure;
@course-data-and-analysis: #CD436D;

@course-java: #4ea3c2;
@course-business: #cc4b88;

@points-problem: #eac73d;
@points-60: #bf3a2a;
@points-70: #dd7633;
@points-80: #26ada1;
@points-100: #368586;

@kingsland-01: #090d4d;
@kingsland-02: #08bfdf;

@simplycode-01: darken(@red, 10%);
@simplycode-02: @red;



@correct-hint: 0 0 0 @quiz-border-width #31CD4B;
@incorrect-hint: 0 0 0 @quiz-border-width @red;

/*sizes*/
@image-padding: 9px;
@global-spacing: 13px;
@popup-padding: 24px;
@button-border: 5px;
@border-radius: 3px;
@border-radius-sm: 2px;
@default-spacing: 13px;
@default-spacing-2: @default-spacing * 2;
@default-spacing-3: @default-spacing * 3;
@default-spacing-4: @default-spacing * 4;
@default-spacing-5: @default-spacing * 5;

@header-bar-height: 60px;
@header-bar-height-small: 50px;

@notification-height: 82px;
@notification-height-small: 50px;
@instructions-toolbar-height: 40px;
@lesson-page-top: 0;
@lesson-page-margin: 20px;

@toolbar-min-height: 40px;
@toolbar-height: 60px;
@toolbar-margin: ((@toolbar-height - @instructions-toolbar-height) / 2);

@screen-xsm: 500px;
@screen-sm: 767px;
@screen-md: 992px;
@screen-lg: 1200px;
@screen-xl: 1600px;
@screen-ipad: 1024px;

@task-steps-margin: 18px;

@lesson-instructions-width-lg: 560px;
@lesson-instructions-width: 490px;
@lesson-instructions-width-md: 460px;
@lesson-instructions-width-sm: 320px;

@lesson-margin: 10px;

@instructions-padding-left: 36px;
@instructions-padding-right: 36px;
@instructions-padding-left-small: 16px;
@instructions-padding-right-small: 10px;
@instructions-padding-left-medium: 20px;
@instructions-padding-right-medium: 20px;

@instructions-padding-top: 12px;
@instructions-padding-top-small: 12px;
@instructions-padding-top-medium: 12px;

//Variables for the backgrounds
@background-options: 13;
@background-url: '@{cloud-path}profile/profile-bg-';
@background-extension: '.jpg';
@background-transition: .4s;

//JR Plaform
@jr-font-size-sm: 18px;
@jr-font-size: 20px;
@jr-font-size-md: 22px;
@jr-font-size-lg: 30px;
@jr-font-size-xlg: 40px;

@defaut-transition: .4s; 

.dialog-titlebar(@name, @titlebar-background, @titlebar-color) {
  .ui-dialog.-@{name} {
    .ui-dialog-titlebar {
      background-color: @titlebar-background;
    }

    .ui-dialog-titlebar-close,
    .ui-dialog-title {
       color: @titlebar-color;
    }

    .@{name} {
      border-radius: 0;
    }
  }
}

.box-hover-border() {
  .shadow(0px 0px 5px 2px rgba(0,0,0,0.2));
    
  border-color: @blue-1;
}

//Add bold affect for things like icons
.thicken(@color, @size: 1px 0px 0px) {
  text-shadow: @size rgba(red(@color), green(@color), blue(@color), 1);
}

.time-pill(@color, @bg-color: @color-light) {
  background-color: @bg-color;
  color: @color;
  display: inline;
  font-size: @size-x-small;
  letter-spacing: .1em;
  padding: 2px 10px;
  text-transform: uppercase;

  .set-radius(15px);
}

.box-sizing(@type: border-box) {
  -webkit-box-sizing: @type;
  -moz-box-sizing: @type;
  box-sizing: @type;
}

.border-box() {
  .box-sizing();
}

.quiz-shadow() {
  -webkit-box-shadow: 0px 4px 5px 0px rgba(48,132,216,0.18);
  -moz-box-shadow: 0px 4px 5px 0px rgba(48,132,216,0.18);
  box-shadow: 0px 4px 5px 0px rgba(48,132,216,0.18);
}

.input-border() {
  border: 1px solid @grey-three;
  box-shadow: none;

  &:hover {
    border: 1px solid @grey-four;
    color: @grey-four;
  }

  &:focus {
    border: 1px solid @grey-five;
    color: @grey-five;
    background-color: @color-light;
  }
}

.input-border-2() {
  border: none;
  box-shadow: 0 2px 0 @grey-two;
  font-size: 13px;

  &:hover {
    box-shadow: 0 2px 0 @grey-three;
    color: @grey-five;
  }

  &:focus {
    box-shadow: 0 2px 0 @grey-four;
    color: #222;
  }
}

.input(@background: @white, @border: @grey-two, @height: 40px, @width: 100%) {
  background: @background;
  border: 1px solid @border;
  color: #282828;
  height: @height;
  letter-spacing: .2px;
  padding: 10px;
  width: @width; 

  &.invalid {
    background-color: fade(@red, 10%);
    box-shadow: none;
  }

  &.is-disabled {
    background-color: @grey-two;
    pointer-events: none;
  }

  .set-radius(5px);
}

.no-word-wrapping {
  white-space: nowrap;
  white-space: pre;
}

.negative-text {
  color: #f15a29;
  font-weight: bold;
}

.positive-text {
  color: #2bb673;
  font-weight: bold;
}

.hero-text() {
  font-size: @size-h2;
  font-weight: 400;
  line-height: 1.1em;

  @media (min-width: @screen-md) {
    font-size: 60px;
  }
}

.header-text() {
  font-weight: 900;
  font-size: @size-h3;
  //opacity: .5;
  text-transform: initial;

  @media (min-width: @screen-md) {
    font-size: @size-h2;
  }
}

.page-container {
  margin: 0 auto; 
  max-width: 1025px;
  padding: @global-spacing * 3 @global-spacing;

  @media (min-width: @screen-sm) { 
    padding: @global-spacing * 3 @global-spacing * 2;
  }
}


.appearance-none() {
  -webkit-appearance: none;
  -moz-appearance: none;
  appearance: none;
}

.input-box-2() {
  background-color: #FFF;
  border-radius: 2px;
  font-size: 12px;
  color: @grey-four;
  cursor: pointer;
  letter-spacing: .5px;
  margin: 0px;
  padding: 9px;

  .appearance-none();
}

.input-border-box() {
  .input-box-2();
  .input-border-2();
}


.hover(@move:2px) {
  @initial: 2px;

  box-shadow: 0 @initial 0 0 @grey-three;
  position: relative;
  top: 0;
  transition: all .2s;

  &:not(.-disable):hover {
    cursor: pointer;
    box-shadow: 0 (@initial+@move) 0 0 @grey-three;
    top: -@move;
  }

  &:active {
    box-shadow: 0 @initial 0 0 @grey-three;
    top: 1px;
  }
}

.hover-effect() {
  cursor: pointer;
  opacity: .8;
  transition: opacity @background-transition;

  &:hover {
    opacity: 1;
  }
}

.opacity-hover-effect() {
  .hover-effect();
}

.darken-hover-effect(@color, @background, @border) {
  border-color: darken(@border, 20%);
  background-color: darken(@background, 20%);
  color: @color;
  cursor: pointer;
}

.change-hover-effect(@color, @background, @border) {
  border-color: @border;
  background-color: @background;
  color: @color;
  cursor: pointer;
}

.h1() {
  font: @size-h2 @font-museo;
  font-weight: 700;
  margin-bottom: 54px;
  text-align: center;
  text-transform: none;
}

.h2() {
  font: @size-h3 @font-museo;
  font-weight: 700;
  margin: 24px 0 12px;
  padding: 6px 0;
  text-align: center;
  text-transform: none;
}

.h3() {
  font: @size-h4 @font-museo;
  font-weight: 700;
  line-height: 1.2em;
  margin: 18px 0 6px;
  padding: 6px 0;
  text-align: center;
  text-transform: none;
}

.h4() {
  font: @size-h5 @font-museo;
  font-weight: 500;
  margin-bottom: 6px;
  /*text-align: center;*/
  text-transform: none;
}

.h5() {
  font: @size-h6 @font-museo;
  font-weight: 600;
  text-transform: capitalize;
}

.banner-header(){
  font: @size-h1 @font-museo;
  font-weight: 500;
  color: @white;
  margin: 0 0 30px 0;
  line-height: 44px;
}

.banner-subheader(){
  font: 22px @font-museo;
  color: @white;
  line-height: 1.3em;
  margin: -20px 0 30px 0;
}

.p {
  font: @size-p @font-museo;
  font-weight: 300;
  letter-spacing: .2px;
  line-height: 1.3em;
  margin: 3px 0;
}

.small() {
  font-size: 9px;
  font-weight: 800;
  letter-spacing: .8px;
  text-transform: uppercase;
}

.removeBreaks {
  br {
    display: none;

    @media (min-width: @screen-md) {
      display: inline;
    }
  }
}

.link {
  cursor: pointer;
  display: block;
  font: @size-p @font-museo;
  font-weight: 500;
  line-height: 1.3em;
  margin: 10px 0 0;
  opacity: .8;
  text-decoration: none;
  text-transform: none;

  &:hover {
    opacity: 1;
  }
}

.truncate {
  white-space: nowrap;
  overflow: hidden;
  text-overflow: ellipsis;
}

.multiLineTruncate(@lineHeight: 1.2em, @lineCount: 1, @bgColor: transparent){
  overflow: hidden;
  position: relative;
  line-height: @lineHeight;
  max-height: @lineHeight * @lineCount; 
  text-align: justify;
  margin-right: -1em;
  padding-right: 1em;
  
  &:before {
    content: '...';
    position: absolute;
    right: 0;
    bottom: 0;
  }

  &:after {
    content: '';
    position: absolute;
    right: 0;
    width: 1em;
    height: 1em;
    margin-top: 0.2em;
    background: @bgColor;
  }
}

.section-header() {
  font-size: @size-h4;
  font-weight: 500;
  line-height: 1em;
  margin-bottom: @default-spacing;
}

.section-text() {
  font-size: @size-h5;
  font-weight: 500;
  line-height: 1em;
  margin-bottom: @default-spacing * 3;
}

//Create background options
.backgroundOption(@n) when (@n > 0) {
  .backgroundOption((@n - 1));
  & .-background-@{n} {
    background-image: url('@{background-url}@{n}@{background-extension}');
  }
}



//Width change transition
.transition-width {
  -webkit-transition: width @defaut-transition; /* Safari */
  transition: width @defaut-transition;
}

//Left position transition
.transition-left {
  -webkit-transition: left .5s; /* Safari */
  transition: left .5s;
}

.transition(@transition) {
  transition: @transition;
  -webkit-transition: @transition;
  -moz-transition: @transition;
  -ms-transition: @transition;
  -o-transition: @transition;
}

.scroll-light() {
  .jspDrag {
    background-color: #9ea3b5;
  }

  .jspTrack {
    background-color: transparent;
    border: none;
  }
}

.verticalAlign(@percent: 50%) {
  position: relative;
  top: @percent;
  transform: translateY(-@percent);
  -webkit-transform: translateY(-@percent);
  -ms-transform: translateY(-@percent);
}

.verticalAlignAbsolute(@percent: 50%) {
  .verticalAlign(@percent);
  position: absolute;
}

.verticalAlignAbsoluteReset {
  position: relative;
  top: none;
  transform: none;
  -webkit-transform: none;
  -ms-transform: none;
}

.verticalAlignFixed {
  .verticalAlign(50%);
  position: fixed;
}

.absoluteCenter {
  left: 0;
  margin: 0 auto;
  position: absolute;
  right: 0;
}

/*
.clearfix {
  display: block;
  content: "";
  clear: both;
  height: 0;
}
*/
.clearfix() {

  &:before,
  &:after {
    content: " ";
    display: table;
  }
  &:after {
    clear: both;
  }

  visibility: visible !important;
  height: initial !important;
}

.dialog-white-buttons() {
  color: @white !important;
  margin: 0 10px;
  padding-left: 16px !important;
  padding-right: 16px !important;
  opacity: .8;

  &:hover,
  &:focus {
    opacity: 1;
    background: transparent;
  }

}

//Default bottom shadow
.shadow(@shadow) {
  -webkit-box-shadow: @shadow;
  -moz-box-shadow: @shadow;
  box-shadow: @shadow;
}

.lesson-border() {
  border-color: rgba(255,255,255,.5);
}

.verticalGradient(@color1, @color2, @transparent:100%) {
  background: fade(@color1, 100%); /* Old browsers */
  background: -moz-linear-gradient(top, fade(@color1, @transparent) 0%, fade(@color2, 100%) 100%); /* FF3.6-15 */
  background: -webkit-linear-gradient(top, fade(@color1, @transparent) 0%,fade(@color2, 100%) 100%); /* Chrome10-25,Safari5.1-6 */
  background: linear-gradient(to bottom, fade(@color1, @transparent) 0%,fade(@color2, 100%) 100%); /* W3C, IE10+, FF16+, Chrome26+, Opera12+, Safari7+ */
  filter: progid:DXImageTransform.Microsoft.gradient( startColorstr='@color1', endColorstr='@color2',GradientType=0 ); /* IE6-9 */
}

.horizontalGradient(@color1, @color2, @transparent:100%) {
  background: @color1; /* Old browsers */
  background: -moz-linear-gradient(left, fade(@color1, @transparent) 0%, fade(@color2, 100%) 100%); /* FF3.6-15 */
  background: -webkit-linear-gradient(left, fade(@color1, @transparent) 0%, fade(@color2, 100%) 100%); /* Chrome10-25,Safari5.1-6 */
  background: linear-gradient(to right, fade(@color1, @transparent) 0%, fade(@color2, 100%) 100%); /* W3C, IE10+, FF16+, Chrome26+, Opera12+, Safari7+ */
  filter: progid:DXImageTransform.Microsoft.gradient( startColorstr='@color1', endColorstr='@color2',GradientType=1 ); /* IE6-9 */
}

.border-image(@color:#fff) {
  -webkit-border-image:
    -webkit-gradient(linear, 0 0, 0 100%, from(@color), to(rgba(0, 0, 0, 0))) 1 100%;
  -webkit-border-image:
    -webkit-linear-gradient(@color, rgba(0, 0, 0, 0)) 1 100%;
  -moz-border-image:
    -moz-linear-gradient(@color, rgba(0, 0, 0, 0)) 1 100%;
  -o-border-image:
    -o-linear-gradient(@color, rgba(0, 0, 0, 0)) 1 100%;
  border-image:
    linear-gradient(to bottom, @color, rgba(0, 0, 0, 0)) 1 100%;
}

.transition(@transition) {
  -webkit-transition: @transition;
  -moz-transition: @transition;
  -o-transition: @transition;
  transition: @transition;
}

.no-select {
  -webkit-touch-callout: none;
  -webkit-user-select: none;
  -khtml-user-select: none;
  -moz-user-select: none;
  -ms-user-select: none;
  user-select: none;
}

.input-select {
  background-color: @color-light;
  border: 0px;
  outline: none;
  width: 100%;
  cursor: pointer;
}

.input-select-wrapper {
  background-color: @color-light;
  border: 1px solid @grey-three;
  padding: 0px 5px;
  cursor: pointer;
}

.input-select-disabled {
  pointer-events: none;
  opacity: .5;
}

.box {
  background-color: @color-light;
  box-shadow: 0 2px 0 0 @grey-three;
  padding: @global-spacing;
}


//Extend the base icon font file
.i-base() {
  &:before {
    &:extend(.icon-font);
  }
}

.icon-close() {
  .i-base();
  &:before {
    &:extend(.icon-close:before);
  }
}

.set-placeholder(@color, @transform: uppercase) {
  &::-webkit-input-placeholder { /* Edge */
    color: @color;
    text-transform: @transform;
  }
  
  &:-ms-input-placeholder { /* Internet Explorer 10-11 */
    color: @color;
    text-transform: @transform;
  }
  
  &::placeholder {
    color: @color;
    text-transform: @transform;
  }
}

.set-radius(@radius) {
  -webkit-border-radius: @radius;
  -moz-border-radius: @radius;
  border-radius: @radius;
}

.border-radius-top(@radius) {
  -webkit-border-top-left-radius: @radius;
  -webkit-border-top-right-radius: @radius;
  -moz-border-radius-topleft: @radius;
  -moz-border-radius-topright: @radius;
  border-top-left-radius: @radius;
  border-top-right-radius: @radius;
}

.border-radius-bottom(@radius) {
  -webkit-border-bottom-left-radius: @radius;
  -webkit-border-bottom-right-radius: @radius;
  -moz-border-radius-bottomleft: @radius;
  -moz-border-radius-bottomright: @radius;
  border-bottom-left-radius: @radius;
  border-bottom-right-radius: @radius;
}

.jr-button(@width:none, @height:@width) {
  border-radius: 10px;
  cursor: pointer;
  position: relative;
  top: 0;
  -webkit-box-shadow: 0 7px 0 #bbbbbb, 0 8px 3px rgba(0, 0, 0, 0.2);
          box-shadow: 0 7px 0 #bbbbbb, 0 8px 3px rgba(0, 0, 0, 0.2);

  &:active, &.active {
    top: 5px;
    -webkit-transition-property: all;
            transition-property: all;
    -webkit-transition-duration: .15s;
            transition-duration: .15s;
    -webkit-box-shadow: 0 2px 0 #bbbbbb, 0 3px 3px rgba(0, 0, 0, 0.2);
            box-shadow: 0 2px 0 #bbbbbb, 0 3px 3px rgba(0, 0, 0, 0.2);
  }
}

.jr-button(@width:none, @height:@width) when not (@width = none) {
  width: @width;
  height: @height;
  padding: @height * 0.2;
  font-size: @height * 0.6;
  border-radius: @height * 0.1;
}

.disable-select() {
  -webkit-user-select: none;
  -moz-user-select: none;
  -ms-user-select: none;
  user-select: none;
}

.neon-glow(@color) {

  text-shadow: 0 0 0 rgba(255,255,255,1) , 0 0 8px rgba(255,255,255,1) , 0 0 20px rgba(255,255,255,1) , 0 0 10px @color , 0 0 10px @color , 0 0 10px @color , 0 0 10px @color ;
  -webkit-transition: all 200ms cubic-bezier(0.42, 0, 0.58, 1);
  -moz-transition: all 200ms cubic-bezier(0.42, 0, 0.58, 1);
  -o-transition: all 200ms cubic-bezier(0.42, 0, 0.58, 1);
  transition: all 200ms cubic-bezier(0.42, 0, 0.58, 1);

}









/*BOOTSTRAP*/

.img-responsive {
  display: block;
  max-width: 100%;
  height: auto;
}
.img-rounded {
  border-radius: 6px;
}
.img-thumbnail {
  padding: 4px;
  line-height: 1.42857143;
  background-color: #ffffff;
  border: 1px solid #dddddd;
  border-radius: 4px;
  -webkit-transition: all 0.2s ease-in-out;
  -o-transition: all 0.2s ease-in-out;
  transition: all 0.2s ease-in-out;
  display: inline-block;
  max-width: 100%;
  height: auto;
}
.img-circle {
  border-radius: 50%;
}

.sr-only {
  position: absolute;
  width: 1px;
  height: 1px;
  margin: -1px;
  padding: 0;
  overflow: hidden;
  clip: rect(0, 0, 0, 0);
  border: 0;
}
.sr-only-focusable:active,
.sr-only-focusable:focus {
  position: static;
  width: auto;
  height: auto;
  margin: 0;
  overflow: visible;
  clip: auto;
}

@defautContainerWidthLarge: 1170px;
@defaultContainerWidthSmall: 970px;
.defaultContainer(@size: @defautContainerWidthLarge) {
  height: 100%;
  margin-right: auto;
  margin-left: auto;
  padding-left: 15px;
  padding-right: 15px;

  @media (min-width: @screen-sm) {
    width: 750px;
  }
  @media (min-width: @screen-md) {
    width: 970px;
  }
  @media (min-width: @screen-lg) {
    width: @size;
  }
}

.section-background() {
  .defaultContainer();
  background-position: center;
  background-size: cover;
  background-repeat: no-repeat;
  bottom: 0;
  left: 0;
  position: absolute;
  right: 0;
  top: 0;
}

.section-background-left() {
  .section-background();
  margin-right: 0;

  @media (min-width: @screen-lg) {
    background-position: center;
    margin-left: auto;
    margin-right: auto;
  }

}

.section-background-right() {
  .section-background();
  margin-left: 0;

  @media (min-width: @screen-lg) {
    background-position: center;
    margin-left: auto;
    margin-right: auto;
  }

}

@gradient-color: rgba(0,111,166,1);
.section-gradient-left() {
  background: @gradient-color;
  background: -moz-linear-gradient(left, rgba(0,111,166,1) 0%, rgba(0,111,166,1) 35%, rgba(0,110,166,0.28) 79%, rgba(0,110,166,0.28) 100%);
  background: -webkit-gradient(left top, right top, color-stop(0%, rgba(0,111,166,1)), color-stop(35%, rgba(0,111,166,1)), color-stop(79%, rgba(0,110,166,0.28)), color-stop(100%, rgba(0,110,166,0.28)));
  background: -webkit-linear-gradient(left, rgba(0,111,166,1) 0%, rgba(0,111,166,1) 35%, rgba(0,110,166,0.28) 79%, rgba(0,110,166,0.28) 100%);
  background: -o-linear-gradient(left, rgba(0,111,166,1) 0%, rgba(0,111,166,1) 35%, rgba(0,110,166,0.28) 79%, rgba(0,110,166,0.28) 100%);
  background: -ms-linear-gradient(left, rgba(0,111,166,1) 0%, rgba(0,111,166,1) 35%, rgba(0,110,166,0.28) 79%, rgba(0,110,166,0.28) 100%);
  background: linear-gradient(to right, rgba(0,111,166,1) 0%, rgba(0,111,166,1) 35%, rgba(0,110,166,0.28) 79%, rgba(0,110,166,0.28) 100%);
  filter: progid:DXImageTransform.Microsoft.gradient( startColorstr='#006fa6', endColorstr='#006ea6', GradientType=1 );
  bottom: 0;
  left: 0;
  position: absolute;
  right: 0;
  top: 0;

  @media (min-width: @screen-lg) {
    background: rgba(0,111,166,1);
    background: -moz-linear-gradient(left, rgba(0,111,166,1) 0%, rgba(0,111,166,1) 35%, rgba(0,110,166,0.28) 58%, rgba(0,110,166,1) 79%, rgba(0,110,166,1) 100%);
    background: -webkit-gradient(left top, right top, color-stop(0%, rgba(0,111,166,1)), color-stop(35%, rgba(0,111,166,1)), color-stop(58%, rgba(0,110,166,0.28)), color-stop(79%, rgba(0,110,166,1)), color-stop(100%, rgba(0,110,166,1)));
    background: -webkit-linear-gradient(left, rgba(0,111,166,1) 0%, rgba(0,111,166,1) 35%, rgba(0,110,166,0.28) 58%, rgba(0,110,166,1) 79%, rgba(0,110,166,1) 100%);
    background: -o-linear-gradient(left, rgba(0,111,166,1) 0%, rgba(0,111,166,1) 35%, rgba(0,110,166,0.28) 58%, rgba(0,110,166,1) 79%, rgba(0,110,166,1) 100%);
    background: -ms-linear-gradient(left, rgba(0,111,166,1) 0%, rgba(0,111,166,1) 35%, rgba(0,110,166,0.28) 58%, rgba(0,110,166,1) 79%, rgba(0,110,166,1) 100%);
    background: linear-gradient(to right, rgba(0,111,166,1) 0%, rgba(0,111,166,1) 35%, rgba(0,110,166,0.28) 58%, rgba(0,110,166,1) 79%, rgba(0,110,166,1) 100%);
    filter: progid:DXImageTransform.Microsoft.gradient( startColorstr='#006fa6', endColorstr='#006ea6', GradientType=1 );
  }
}

.section-gradient-right() {
  background: rgba(0,110,166,0.28);
  background: -moz-linear-gradient(left, rgba(0,110,166,0.28) 0%, rgba(0,110,166,0.28) 21%, rgba(0,111,166,1) 65%, rgba(0,111,166,1) 100%);
  background: -webkit-gradient(left top, right top, color-stop(0%, rgba(0,110,166,0.28)), color-stop(21%, rgba(0,110,166,0.28)), color-stop(65%, rgba(0,111,166,1)), color-stop(100%, rgba(0,111,166,1)));
  background: -webkit-linear-gradient(left, rgba(0,110,166,0.28) 0%, rgba(0,110,166,0.28) 21%, rgba(0,111,166,1) 65%, rgba(0,111,166,1) 100%);
  background: -o-linear-gradient(left, rgba(0,110,166,0.28) 0%, rgba(0,110,166,0.28) 21%, rgba(0,111,166,1) 65%, rgba(0,111,166,1) 100%);
  background: -ms-linear-gradient(left, rgba(0,110,166,0.28) 0%, rgba(0,110,166,0.28) 21%, rgba(0,111,166,1) 65%, rgba(0,111,166,1) 100%);
  background: linear-gradient(to right, rgba(0,110,166,0.28) 0%, rgba(0,110,166,0.28) 21%, rgba(0,111,166,1) 65%, rgba(0,111,166,1) 100%);
  filter: progid:DXImageTransform.Microsoft.gradient( startColorstr='#006ea6', endColorstr='#006fa6', GradientType=1 );
  bottom: 0;
  left: 0;
  position: absolute;
  right: 0;
  top: 0;

  @media (min-width: @screen-lg) {
    background: rgba(0,110,166,1);
    background: -moz-linear-gradient(left, rgba(0,110,166,1) 0%, rgba(0,110,166,1) 21%, rgba(0,110,166,0.28) 42%, rgba(0,111,166,1) 65%, rgba(0,111,166,1) 100%);
    background: -webkit-gradient(left top, right top, color-stop(0%, rgba(0,110,166,1)), color-stop(21%, rgba(0,110,166,1)), color-stop(42%, rgba(0,110,166,0.28)), color-stop(65%, rgba(0,111,166,1)), color-stop(100%, rgba(0,111,166,1)));
    background: -webkit-linear-gradient(left, rgba(0,110,166,1) 0%, rgba(0,110,166,1) 21%, rgba(0,110,166,0.28) 42%, rgba(0,111,166,1) 65%, rgba(0,111,166,1) 100%);
    background: -o-linear-gradient(left, rgba(0,110,166,1) 0%, rgba(0,110,166,1) 21%, rgba(0,110,166,0.28) 42%, rgba(0,111,166,1) 65%, rgba(0,111,166,1) 100%);
    background: -ms-linear-gradient(left, rgba(0,110,166,1) 0%, rgba(0,110,166,1) 21%, rgba(0,110,166,0.28) 42%, rgba(0,111,166,1) 65%, rgba(0,111,166,1) 100%);
    background: linear-gradient(to right, rgba(0,110,166,1) 0%, rgba(0,110,166,1) 21%, rgba(0,110,166,0.28) 42%, rgba(0,111,166,1) 65%, rgba(0,111,166,1) 100%);
    filter: progid:DXImageTransform.Microsoft.gradient( startColorstr='#006ea6', endColorstr='#006fa6', GradientType=1 );
  }
}

.container {
  .defaultContainer();
}
.container-fluid {
  margin-right: auto;
  margin-left: auto;
  padding-left: 15px;
  padding-right: 15px;
}

.section {
  margin-left: -15px;
  margin-right: -15px;
}

.col-xs-1, .col-sm-1, .col-md-1, .col-lg-1, .col-xs-2, .col-sm-2, .col-md-2, .col-lg-2, .col-xs-3, .col-sm-3, .col-md-3, .col-lg-3, .col-xs-4, .col-sm-4, .col-md-4, .col-lg-4, .col-xs-5, .col-sm-5, .col-md-5, .col-lg-5, .col-xs-6, .col-sm-6, .col-md-6, .col-lg-6, .col-xs-7, .col-sm-7, .col-md-7, .col-lg-7, .col-xs-8, .col-sm-8, .col-md-8, .col-lg-8, .col-xs-9, .col-sm-9, .col-md-9, .col-lg-9, .col-xs-10, .col-sm-10, .col-md-10, .col-lg-10, .col-xs-11, .col-sm-11, .col-md-11, .col-lg-11, .col-xs-12, .col-sm-12, .col-md-12, .col-lg-12 {
  position: relative;
  min-height: 1px;
  padding-left: 15px;
  padding-right: 15px;
}
.col-xs-1, .col-xs-2, .col-xs-3, .col-xs-4, .col-xs-5, .col-xs-6, .col-xs-7, .col-xs-8, .col-xs-9, .col-xs-10, .col-xs-11, .col-xs-12 {
  float: left;
}
.col-xs-12 {
  width: 100%;
}
.col-xs-11 {
  width: 91.66666667%;
}
.col-xs-10 {
  width: 83.33333333%;
}
.col-xs-9 {
  width: 75%;
}
.col-xs-8 {
  width: 66.66666667%;
}
.col-xs-7 {
  width: 58.33333333%;
}
.col-xs-6 {
  width: 50%;
}
.col-xs-5 {
  width: 41.66666667%;
}
.col-xs-4 {
  width: 33.33333333%;
}
.col-xs-3 {
  width: 25%;
}
.col-xs-2 {
  width: 16.66666667%;
}
.col-xs-1 {
  width: 8.33333333%;
}
.col-xs-pull-12 {
  right: 100%;
}
.col-xs-pull-11 {
  right: 91.66666667%;
}
.col-xs-pull-10 {
  right: 83.33333333%;
}
.col-xs-pull-9 {
  right: 75%;
}
.col-xs-pull-8 {
  right: 66.66666667%;
}
.col-xs-pull-7 {
  right: 58.33333333%;
}
.col-xs-pull-6 {
  right: 50%;
}
.col-xs-pull-5 {
  right: 41.66666667%;
}
.col-xs-pull-4 {
  right: 33.33333333%;
}
.col-xs-pull-3 {
  right: 25%;
}
.col-xs-pull-2 {
  right: 16.66666667%;
}
.col-xs-pull-1 {
  right: 8.33333333%;
}
.col-xs-pull-0 {
  right: auto;
}
.col-xs-push-12 {
  left: 100%;
}
.col-xs-push-11 {
  left: 91.66666667%;
}
.col-xs-push-10 {
  left: 83.33333333%;
}
.col-xs-push-9 {
  left: 75%;
}
.col-xs-push-8 {
  left: 66.66666667%;
}
.col-xs-push-7 {
  left: 58.33333333%;
}
.col-xs-push-6 {
  left: 50%;
}
.col-xs-push-5 {
  left: 41.66666667%;
}
.col-xs-push-4 {
  left: 33.33333333%;
}
.col-xs-push-3 {
  left: 25%;
}
.col-xs-push-2 {
  left: 16.66666667%;
}
.col-xs-push-1 {
  left: 8.33333333%;
}
.col-xs-push-0 {
  left: auto;
}
.col-xs-offset-12 {
  margin-left: 100%;
}
.col-xs-offset-11 {
  margin-left: 91.66666667%;
}
.col-xs-offset-10 {
  margin-left: 83.33333333%;
}
.col-xs-offset-9 {
  margin-left: 75%;
}
.col-xs-offset-8 {
  margin-left: 66.66666667%;
}
.col-xs-offset-7 {
  margin-left: 58.33333333%;
}
.col-xs-offset-6 {
  margin-left: 50%;
}
.col-xs-offset-5 {
  margin-left: 41.66666667%;
}
.col-xs-offset-4 {
  margin-left: 33.33333333%;
}
.col-xs-offset-3 {
  margin-left: 25%;
}
.col-xs-offset-2 {
  margin-left: 16.66666667%;
}
.col-xs-offset-1 {
  margin-left: 8.33333333%;
}
.col-xs-offset-0 {
  margin-left: 0%;
}
@media (min-width: 768px) {
  .col-sm-1, .col-sm-2, .col-sm-3, .col-sm-4, .col-sm-5, .col-sm-6, .col-sm-7, .col-sm-8, .col-sm-9, .col-sm-10, .col-sm-11, .col-sm-12 {
    float: left;
  }
  .col-sm-12 {
    width: 100%;
  }
  .col-sm-11 {
    width: 91.66666667%;
  }
  .col-sm-10 {
    width: 83.33333333%;
  }
  .col-sm-9 {
    width: 75%;
  }
  .col-sm-8 {
    width: 66.66666667%;
  }
  .col-sm-7 {
    width: 58.33333333%;
  }
  .col-sm-6 {
    width: 50%;
  }
  .col-sm-5 {
    width: 41.66666667%;
  }
  .col-sm-4 {
    width: 33.33333333%;
  }
  .col-sm-3 {
    width: 25%;
  }
  .col-sm-2 {
    width: 16.66666667%;
  }
  .col-sm-1 {
    width: 8.33333333%;
  }
  .col-sm-pull-12 {
    right: 100%;
  }
  .col-sm-pull-11 {
    right: 91.66666667%;
  }
  .col-sm-pull-10 {
    right: 83.33333333%;
  }
  .col-sm-pull-9 {
    right: 75%;
  }
  .col-sm-pull-8 {
    right: 66.66666667%;
  }
  .col-sm-pull-7 {
    right: 58.33333333%;
  }
  .col-sm-pull-6 {
    right: 50%;
  }
  .col-sm-pull-5 {
    right: 41.66666667%;
  }
  .col-sm-pull-4 {
    right: 33.33333333%;
  }
  .col-sm-pull-3 {
    right: 25%;
  }
  .col-sm-pull-2 {
    right: 16.66666667%;
  }
  .col-sm-pull-1 {
    right: 8.33333333%;
  }
  .col-sm-pull-0 {
    right: auto;
  }
  .col-sm-push-12 {
    left: 100%;
  }
  .col-sm-push-11 {
    left: 91.66666667%;
  }
  .col-sm-push-10 {
    left: 83.33333333%;
  }
  .col-sm-push-9 {
    left: 75%;
  }
  .col-sm-push-8 {
    left: 66.66666667%;
  }
  .col-sm-push-7 {
    left: 58.33333333%;
  }
  .col-sm-push-6 {
    left: 50%;
  }
  .col-sm-push-5 {
    left: 41.66666667%;
  }
  .col-sm-push-4 {
    left: 33.33333333%;
  }
  .col-sm-push-3 {
    left: 25%;
  }
  .col-sm-push-2 {
    left: 16.66666667%;
  }
  .col-sm-push-1 {
    left: 8.33333333%;
  }
  .col-sm-push-0 {
    left: auto;
  }
  .col-sm-offset-12 {
    margin-left: 100%;
  }
  .col-sm-offset-11 {
    margin-left: 91.66666667%;
  }
  .col-sm-offset-10 {
    margin-left: 83.33333333%;
  }
  .col-sm-offset-9 {
    margin-left: 75%;
  }
  .col-sm-offset-8 {
    margin-left: 66.66666667%;
  }
  .col-sm-offset-7 {
    margin-left: 58.33333333%;
  }
  .col-sm-offset-6 {
    margin-left: 50%;
  }
  .col-sm-offset-5 {
    margin-left: 41.66666667%;
  }
  .col-sm-offset-4 {
    margin-left: 33.33333333%;
  }
  .col-sm-offset-3 {
    margin-left: 25%;
  }
  .col-sm-offset-2 {
    margin-left: 16.66666667%;
  }
  .col-sm-offset-1 {
    margin-left: 8.33333333%;
  }
  .col-sm-offset-0 {
    margin-left: 0%;
  }
}
@media (min-width: 992px) {
  .col-md-1, .col-md-2, .col-md-3, .col-md-4, .col-md-5, .col-md-6, .col-md-7, .col-md-8, .col-md-9, .col-md-10, .col-md-11, .col-md-12 {
    float: left;
  }
  .col-md-12 {
    width: 100%;
  }
  .col-md-11 {
    width: 91.66666667%;
  }
  .col-md-10 {
    width: 83.33333333%;
  }
  .col-md-9 {
    width: 75%;
  }
  .col-md-8 {
    width: 66.66666667%;
  }
  .col-md-7 {
    width: 58.33333333%;
  }
  .col-md-6 {
    width: 50%;
  }
  .col-md-5 {
    width: 41.66666667%;
  }
  .col-md-4 {
    width: 33.33333333%;
  }
  .col-md-3 {
    width: 25%;
  }
  .col-md-2 {
    width: 16.66666667%;
  }
  .col-md-1 {
    width: 8.33333333%;
  }
  .col-md-pull-12 {
    right: 100%;
  }
  .col-md-pull-11 {
    right: 91.66666667%;
  }
  .col-md-pull-10 {
    right: 83.33333333%;
  }
  .col-md-pull-9 {
    right: 75%;
  }
  .col-md-pull-8 {
    right: 66.66666667%;
  }
  .col-md-pull-7 {
    right: 58.33333333%;
  }
  .col-md-pull-6 {
    right: 50%;
  }
  .col-md-pull-5 {
    right: 41.66666667%;
  }
  .col-md-pull-4 {
    right: 33.33333333%;
  }
  .col-md-pull-3 {
    right: 25%;
  }
  .col-md-pull-2 {
    right: 16.66666667%;
  }
  .col-md-pull-1 {
    right: 8.33333333%;
  }
  .col-md-pull-0 {
    right: auto;
  }
  .col-md-push-12 {
    left: 100%;
  }
  .col-md-push-11 {
    left: 91.66666667%;
  }
  .col-md-push-10 {
    left: 83.33333333%;
  }
  .col-md-push-9 {
    left: 75%;
  }
  .col-md-push-8 {
    left: 66.66666667%;
  }
  .col-md-push-7 {
    left: 58.33333333%;
  }
  .col-md-push-6 {
    left: 50%;
  }
  .col-md-push-5 {
    left: 41.66666667%;
  }
  .col-md-push-4 {
    left: 33.33333333%;
  }
  .col-md-push-3 {
    left: 25%;
  }
  .col-md-push-2 {
    left: 16.66666667%;
  }
  .col-md-push-1 {
    left: 8.33333333%;
  }
  .col-md-push-0 {
    left: auto;
  }
  .col-md-offset-12 {
    margin-left: 100%;
  }
  .col-md-offset-11 {
    margin-left: 91.66666667%;
  }
  .col-md-offset-10 {
    margin-left: 83.33333333%;
  }
  .col-md-offset-9 {
    margin-left: 75%;
  }
  .col-md-offset-8 {
    margin-left: 66.66666667%;
  }
  .col-md-offset-7 {
    margin-left: 58.33333333%;
  }
  .col-md-offset-6 {
    margin-left: 50%;
  }
  .col-md-offset-5 {
    margin-left: 41.66666667%;
  }
  .col-md-offset-4 {
    margin-left: 33.33333333%;
  }
  .col-md-offset-3 {
    margin-left: 25%;
  }
  .col-md-offset-2 {
    margin-left: 16.66666667%;
  }
  .col-md-offset-1 {
    margin-left: 8.33333333%;
  }
  .col-md-offset-0 {
    margin-left: 0%;
  }
}
@media (min-width: 1200px) {
  .col-lg-1, .col-lg-2, .col-lg-3, .col-lg-4, .col-lg-5, .col-lg-6, .col-lg-7, .col-lg-8, .col-lg-9, .col-lg-10, .col-lg-11, .col-lg-12 {
    float: left;
  }
  .col-lg-12 {
    width: 100%;
  }
  .col-lg-11 {
    width: 91.66666667%;
  }
  .col-lg-10 {
    width: 83.33333333%;
  }
  .col-lg-9 {
    width: 75%;
  }
  .col-lg-8 {
    width: 66.66666667%;
  }
  .col-lg-7 {
    width: 58.33333333%;
  }
  .col-lg-6 {
    width: 50%;
  }
  .col-lg-5 {
    width: 41.66666667%;
  }
  .col-lg-4 {
    width: 33.33333333%;
  }
  .col-lg-3 {
    width: 25%;
  }
  .col-lg-2 {
    width: 16.66666667%;
  }
  .col-lg-1 {
    width: 8.33333333%;
  }
  .col-lg-pull-12 {
    right: 100%;
  }
  .col-lg-pull-11 {
    right: 91.66666667%;
  }
  .col-lg-pull-10 {
    right: 83.33333333%;
  }
  .col-lg-pull-9 {
    right: 75%;
  }
  .col-lg-pull-8 {
    right: 66.66666667%;
  }
  .col-lg-pull-7 {
    right: 58.33333333%;
  }
  .col-lg-pull-6 {
    right: 50%;
  }
  .col-lg-pull-5 {
    right: 41.66666667%;
  }
  .col-lg-pull-4 {
    right: 33.33333333%;
  }
  .col-lg-pull-3 {
    right: 25%;
  }
  .col-lg-pull-2 {
    right: 16.66666667%;
  }
  .col-lg-pull-1 {
    right: 8.33333333%;
  }
  .col-lg-pull-0 {
    right: auto;
  }
  .col-lg-push-12 {
    left: 100%;
  }
  .col-lg-push-11 {
    left: 91.66666667%;
  }
  .col-lg-push-10 {
    left: 83.33333333%;
  }
  .col-lg-push-9 {
    left: 75%;
  }
  .col-lg-push-8 {
    left: 66.66666667%;
  }
  .col-lg-push-7 {
    left: 58.33333333%;
  }
  .col-lg-push-6 {
    left: 50%;
  }
  .col-lg-push-5 {
    left: 41.66666667%;
  }
  .col-lg-push-4 {
    left: 33.33333333%;
  }
  .col-lg-push-3 {
    left: 25%;
  }
  .col-lg-push-2 {
    left: 16.66666667%;
  }
  .col-lg-push-1 {
    left: 8.33333333%;
  }
  .col-lg-push-0 {
    left: auto;
  }
  .col-lg-offset-12 {
    margin-left: 100%;
  }
  .col-lg-offset-11 {
    margin-left: 91.66666667%;
  }
  .col-lg-offset-10 {
    margin-left: 83.33333333%;
  }
  .col-lg-offset-9 {
    margin-left: 75%;
  }
  .col-lg-offset-8 {
    margin-left: 66.66666667%;
  }
  .col-lg-offset-7 {
    margin-left: 58.33333333%;
  }
  .col-lg-offset-6 {
    margin-left: 50%;
  }
  .col-lg-offset-5 {
    margin-left: 41.66666667%;
  }
  .col-lg-offset-4 {
    margin-left: 33.33333333%;
  }
  .col-lg-offset-3 {
    margin-left: 25%;
  }
  .col-lg-offset-2 {
    margin-left: 16.66666667%;
  }
  .col-lg-offset-1 {
    margin-left: 8.33333333%;
  }
  .col-lg-offset-0 {
    margin-left: 0%;
  }
}
.clearfix:before,
.clearfix:after,
.container:before,
.container:after,
.container-fluid:before,
.container-fluid:after,
.section:before,
.section:after {
  content: " ";
  display: table;
}
.clearfix:after,
.container:after,
.container-fluid:after,
.section:after {
  clear: both;
}
.center-block {
  display: block;
  margin-left: auto;
  margin-right: auto;
}
.pull-right {
  float: right !important;
}
.pull-left {
  float: left !important;
}
.hide {
  display: none !important;
}
.show {
  display: block !important;
}
.invisible {
  visibility: hidden;
}
.text-hide {
  font: 0/0 a;
  color: transparent;
  text-shadow: none;
  background-color: transparent;
  border: 0;
}
.hidden {
  display: none !important;
}
.affix {
  position: fixed;
}
@-ms-viewport {
  width: device-width;
}
.visible-xs,
.visible-sm,
.visible-md,
.visible-lg {
  display: none !important;
}
.visible-xs-block,
.visible-xs-inline,
.visible-xs-inline-block,
.visible-sm-block,
.visible-sm-inline,
.visible-sm-inline-block,
.visible-md-block,
.visible-md-inline,
.visible-md-inline-block,
.visible-lg-block,
.visible-lg-inline,
.visible-lg-inline-block {
  display: none !important;
}
@media (max-width: 767px) {
  .visible-xs {
    display: block !important;
  }
  table.visible-xs {
    display: table !important;
  }
  tr.visible-xs {
    display: table-row !important;
  }
  th.visible-xs,
  td.visible-xs {
    display: table-cell !important;
  }
}
@media (max-width: 767px) {
  .visible-xs-block {
    display: block !important;
  }
}
@media (max-width: 767px) {
  .visible-xs-inline {
    display: inline !important;
  }
}
@media (max-width: 767px) {
  .visible-xs-inline-block {
    display: inline-block !important;
  }
}
@media (min-width: 768px) and (max-width: 991px) {
  .visible-sm {
    display: block !important;
  }
  table.visible-sm {
    display: table !important;
  }
  tr.visible-sm {
    display: table-row !important;
  }
  th.visible-sm,
  td.visible-sm {
    display: table-cell !important;
  }
}
@media (min-width: 768px) and (max-width: 991px) {
  .visible-sm-block {
    display: block !important;
  }
}
@media (min-width: 768px) and (max-width: 991px) {
  .visible-sm-inline {
    display: inline !important;
  }
}
@media (min-width: 768px) and (max-width: 991px) {
  .visible-sm-inline-block {
    display: inline-block !important;
  }
}
@media (min-width: 992px) and (max-width: 1199px) {
  .visible-md {
    display: block !important;
  }
  table.visible-md {
    display: table !important;
  }
  tr.visible-md {
    display: table-row !important;
  }
  th.visible-md,
  td.visible-md {
    display: table-cell !important;
  }
}
@media (min-width: 992px) and (max-width: 1199px) {
  .visible-md-block {
    display: block !important;
  }
}
@media (min-width: 992px) and (max-width: 1199px) {
  .visible-md-inline {
    display: inline !important;
  }
}
@media (min-width: 992px) and (max-width: 1199px) {
  .visible-md-inline-block {
    display: inline-block !important;
  }
}
@media (min-width: 1200px) {
  .visible-lg {
    display: block !important;
  }
  table.visible-lg {
    display: table !important;
  }
  tr.visible-lg {
    display: table-row !important;
  }
  th.visible-lg,
  td.visible-lg {
    display: table-cell !important;
  }
}
@media (min-width: 1200px) {
  .visible-lg-block {
    display: block !important;
  }
}
@media (min-width: 1200px) {
  .visible-lg-inline {
    display: inline !important;
  }
}
@media (min-width: 1200px) {
  .visible-lg-inline-block {
    display: inline-block !important;
  }
}
@media (max-width: 767px) {
  .hidden-xs {
    display: none !important;
  }
}
@media (min-width: 768px) and (max-width: 991px) {
  .hidden-sm {
    display: none !important;
  }
}
@media (min-width: 992px) and (max-width: 1199px) {
  .hidden-md {
    display: none !important;
  }
}
@media (min-width: 1200px) {
  .hidden-lg {
    display: none !important;
  }
}
.visible-print {
  display: none !important;
}
@media print {
  .visible-print {
    display: block !important;
  }
  table.visible-print {
    display: table !important;
  }
  tr.visible-print {
    display: table-row !important;
  }
  th.visible-print,
  td.visible-print {
    display: table-cell !important;
  }
}
.visible-print-block {
  display: none !important;
}
@media print {
  .visible-print-block {
    display: block !important;
  }
}
.visible-print-inline {
  display: none !important;
}
@media print {
  .visible-print-inline {
    display: inline !important;
  }
}
.visible-print-inline-block {
  display: none !important;
}
@media print {
  .visible-print-inline-block {
    display: inline-block !important;
  }
}
@media print {
  .hidden-print {
    display: none !important;
  }
}
