@charset "UTF-8";

@font-face {
  font-family: "codeavengers-font";
  src:url("/fonts/codeavengers-font.eot?12.1486");
  src:url("/fonts/codeavengers-font.eot?12.1486#iefix") format("embedded-opentype"),
    url("/fonts/codeavengers-font.woff?12.1486") format("woff"),
    url("/fonts/codeavengers-font.ttf?12.1486") format("truetype"),
    url("/fonts/codeavengers-font.svg#codeavengers-font") format("svg");
  font-weight: normal;
  font-style: normal;

}

[data-icon]:before {
  font-family: "codeavengers-font" !important;
  content: attr(data-icon);
  font-style: normal !important;
  font-weight: normal !important;
  font-variant: normal !important;
  text-transform: none !important;
  speak: none;
  line-height: 1;
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
}

[class^="icon-"]:before,
[class*=" icon-"]:before,
.icon-font,
[class$="_icon"]:before {
  font-family: "codeavengers-font" !important;
  font-style: normal !important;
  font-weight: normal !important;
  font-variant: normal !important;
  text-transform: none !important;
  speak: none;
  line-height: 1;
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
}

.icon-profile:before {
  content: "\57";
}
.icon-twitter-logo:before {
  content: "\61";
}
.icon-ca-logo:before {
  content: "\6b";
}
.icon-connect-four:before {
  content: "\73";
}
.icon-curling:before {
  content: "\77";
}
.icon-diving:before {
  content: "\42";
}
.icon-facebook:before {
  content: "\48";
}
.icon-gym:before {
  content: "\55";
}
.icon-hangman:before {
  content: "\58";
}
.icon-jquery-icon:before {
  content: "\e073";
}
.icon-menu:before {
  content: "\29";
}
.icon-mini-close:before {
  content: "\2a";
}
.icon-mini-play:before {
  content: "\2b";
}
.icon-mystery-pic:before {
  content: "\3c";
}
.icon-ping-pong:before {
  content: "\40";
}
.icon-rsvp:before {
  content: "\e003";
}
.icon-ribbon-small:before {
  content: "\e007";
}
.icon-ribbon-small-empty:before {
  content: "\e008";
}
.icon-ribbon-small-half:before {
  content: "\e009";
}
.icon-tic-tac-toe:before {
  content: "\e012";
}
.icon-treasure-hunt:before {
  content: "\e013";
}
.icon-twitter:before {
  content: "\e014";
}
.icon-video-1:before {
  content: "\e028";
}
.icon-hint:before {
  content: "\e029";
}
.icon-google-job:before {
  content: "\52";
}
.icon-psr:before {
  content: "\7b";
}
.icon-rock:before {
  content: "\e02a";
}
.icon-scissors:before {
  content: "\e02b";
}
.icon-paper:before {
  content: "\e02c";
}
.icon-empty-circle:before {
  content: "\6d";
}
.icon-full-circle:before {
  content: "\e02d";
}
.icon-play:before {
  content: "\e02f";
}
.icon-play-solid:before {
  content: "\e031";
}
.icon-ca-full:before {
  content: "\69";
}
.icon-memory-game:before {
  content: "\e034";
}
.icon-review:before {
  content: "\e033";
}
.icon-google-plus:before {
  content: "\53";
}
.icon-no-credit-card:before {
  content: "\e046";
}
.icon-facebook-square:before {
  content: "\e048";
}
.icon-email:before {
  content: "\e049";
}
.icon-star:before {
  content: "\7e";
}
.icon-full-star:before {
  content: "\e001";
}
.icon-youtube:before {
  content: "\6c";
}
.icon-slide-show:before {
  content: "\56";
}
.icon-quiz:before {
  content: "\e082";
}
.icon-interface:before {
  content: "\e085";
}
.icon-resources:before {
  content: "\e03c";
}
.icon-lesson-plan:before {
  content: "\e04e";
}
.icon-exclamation:before {
  content: "\e01c";
}
.icon-question-mark:before {
  content: "\e03a";
}
.icon-classroom-book:before {
  content: "\e002";
}
.icon-click-racer:before {
  content: "\e03e";
}
.icon-code:before {
  content: "\e04f";
}
.icon-date:before {
  content: "\e050";
}
.icon-down:before {
  content: "\68";
}
.icon-down-arrow:before {
  content: "\72";
}
.icon-external-window:before {
  content: "\50";
}
.icon-golf:before {
  content: "\26";
}
.icon-guide:before {
  content: "\3d";
}
.icon-html-css-icon:before {
  content: "\e051";
}
.icon-individual:before {
  content: "\e052";
}
.icon-intro-graphics:before {
  content: "\e053";
}
.icon-jquery-dollar:before {
  content: "\e054";
}
.icon-left-arrow:before {
  content: "\e055";
}
.icon-mail:before {
  content: "\70";
}
.icon-menu-icon:before {
  content: "\43";
}
.icon-money-run:before {
  content: "\46";
}
.icon-more:before {
  content: "\e078";
}
.icon-plus:before {
  content: "\e015";
}
.icon-price-tag:before {
  content: "\e016";
}
.icon-question:before {
  content: "\e04b";
}
.icon-redo:before {
  content: "\e057";
}
.icon-right-arrow:before {
  content: "\e059";
}
.icon-running:before {
  content: "\e05a";
}
.icon-share:before {
  content: "\e05b";
}
.icon-shot:before {
  content: "\e05c";
}
.icon-progress-bars:before {
  content: "\e077";
}
.icon-minus:before {
  content: "\27";
}
.icon-manuscript:before {
  content: "\28";
}
.icon-camera:before {
  content: "\3e";
}
.icon-postcard:before {
  content: "\e083";
}
.icon-website:before {
  content: "\24";
}
.icon-loop-grid:before {
  content: "\e076";
}
.icon-video-camera:before {
  content: "\5f";
}
.icon-list-bullet:before {
  content: "\5c";
}
.icon-cloud-upload:before {
  content: "\e000";
}
.icon-file:before {
  content: "\e00a";
}
.icon-trash:before {
  content: "\e00b";
}
.icon-shortcut:before {
  content: "\e011";
}
.icon-app:before {
  content: "\65";
}
.icon-search:before {
  content: "\67";
}
.icon-check-circle:before {
  content: "\78";
}
.icon-boxes:before {
  content: "\47";
}
.icon-watch:before {
  content: "\49";
}
.icon-arrow-cross:before {
  content: "\4d";
}
.icon-check:before {
  content: "\5e";
}
.icon-close:before {
  content: "\e00c";
}
.icon-close-circle:before {
  content: "\e017";
}
.icon-right:before {
  content: "\e01e";
}
.icon-left:before {
  content: "\e020";
}
.icon-clipboard:before {
  content: "\e022";
}
.icon-birthday-invite:before {
  content: "\e030";
}
.icon-celebrity:before {
  content: "\e032";
}
.icon-game-editor:before {
  content: "\e037";
}
.icon-eye-spy:before {
  content: "\e039";
}
.icon-flags-challenge:before {
  content: "\e042";
}
.icon-controller:before {
  content: "\e04c";
}
.icon-game-graphics:before {
  content: "\e05e";
}
.icon-stats-calc:before {
  content: "\45";
}
.icon-tennis:before {
  content: "\4b";
}
.icon-undo:before {
  content: "\4e";
}
.icon-up:before {
  content: "\e072";
}
.icon-up-arrow:before {
  content: "\2c";
}
.icon-video:before {
  content: "\2d";
}
.icon-wiki:before {
  content: "\60";
}
.icon-world:before {
  content: "\7d";
}
.icon-resume:before {
  content: "\e00d";
}
.icon-shopping-bag:before {
  content: "\e00e";
}
.icon-progress:before {
  content: "\e024";
}
.icon-settings:before {
  content: "\e02e";
}
.icon-course-notes:before {
  content: "\e03f";
}
.icon-pb-camera:before {
  content: "\6f";
}
.icon-pb-circle-plus:before {
  content: "\44";
}
.icon-pb-circle:before {
  content: "\54";
}
.icon-pb-download:before {
  content: "\59";
}
.icon-help-circled:before {
  content: "\e070";
}
.icon-print:before {
  content: "\e004";
}
.icon-circle-star:before {
  content: "\e005";
}
.icon-ribbon:before {
  content: "\e01a";
}
.icon-circle-ca:before {
  content: "\e010";
}
.icon-swap:before {
  content: "\e01b";
}
.icon-reset:before {
  content: "\e01f";
}
.icon-insert:before {
  content: "\e025";
}
.icon-show-box:before {
  content: "\e026";
}
.icon-hide-box:before {
  content: "\e038";
}
.icon-speech-arrow-right:before {
  content: "\e040";
}
.icon-speech-arrow-left:before {
  content: "\e045";
}
.icon-open:before {
  content: "\e064";
}
.icon-mouths:before {
  content: "\e019";
}
.icon-patterns:before {
  content: "\e066";
}
.icon-tails:before {
  content: "\e067";
}
.icon-top-fins:before {
  content: "\e068";
}
.icon-eyes:before {
  content: "\e069";
}
.icon-colors:before {
  content: "\e06a";
}
.icon-bottom-fins:before {
  content: "\e06b";
}
.icon-audio-off:before {
  content: "\e06d";
}
.icon-audio-on:before {
  content: "\e06e";
}
.icon-0:before {
  content: "\30";
}
.icon-1:before {
  content: "\31";
}
.icon-2:before {
  content: "\32";
}
.icon-3:before {
  content: "\33";
}
.icon-6:before {
  content: "\36";
}
.icon-7:before {
  content: "\37";
}
.icon-8:before {
  content: "\38";
}
.icon-9:before {
  content: "\39";
}
.icon-4:before {
  content: "\34";
}
.icon-5:before {
  content: "\35";
}
.icon-clock:before {
  content: "\e079";
}
.icon-light-bulb:before {
  content: "\e041";
}
.icon-lights:before {
  content: "\e07b";
}
.icon-heads:before {
  content: "\e07c";
}
.icon-events:before {
  content: "\e07f";
}
.icon-noses:before {
  content: "\e07e";
}
.icon-critters:before {
  content: "\e081";
}
.icon-bodies:before {
  content: "\e06c";
}
.icon-period:before {
  content: "\3a";
}
.icon-colon:before {
  content: "\2e";
}
.icon-forward-slash:before {
  content: "\2f";
}
.icon-login:before {
  content: "\e084";
}
.icon-add-user:before {
  content: "\e086";
}
.icon-logout:before {
  content: "\e065";
}
.icon-small-arrow-left:before {
  content: "\e087";
}
.icon-small-arrow-right:before {
  content: "\e088";
}
.icon-not-fun-3:before {
  content: "\e04a";
}
.icon-happy:before {
  content: "\e08c";
}
.icon-good:before {
  content: "\e08d";
}
.icon-hard:before {
  content: "\e08e";
}
.icon-easy:before {
  content: "\e08f";
}
.icon-thumbs-up:before {
  content: "\e060";
}
.icon-excellent:before {
  content: "\e061";
}
.icon-ok:before {
  content: "\e062";
}
.icon-sad:before {
  content: "\4c";
}
.icon-emoticon-neutral-face-outline:before {
  content: "\e089";
}
.icon-control:before {
  content: "\e063";
}
.icon-other-sprites:before {
  content: "\e08b";
}
.icon-text:before {
  content: "\e090";
}
.icon-variables:before {
  content: "\e092";
}
.icon-values:before {
  content: "\e093";
}
.icon-jquery:before {
  content: "\7c";
}
.icon-html:before {
  content: "\e094";
}
.icon-python:before {
  content: "\e095";
}
.icon-monitor:before {
  content: "\e097";
}
.icon-javascript:before {
  content: "\e018";
}
.icon-computer:before {
  content: "\e09a";
}
.icon-characters:before {
  content: "\e021";
}
.icon-book:before {
  content: "\21";
}
.icon-start-up:before {
  content: "\e09c";
}
.icon-manage-people:before {
  content: "\e08a";
}
.icon-presentation:before {
  content: "\e098";
}
.icon-bar-chart:before {
  content: "\e09e";
}
.icon-graph:before {
  content: "\e09f";
}
.icon-confirm-user:before {
  content: "\e0a0";
}
.icon-report:before {
  content: "\e09d";
}
.icon-subscription:before {
  content: "\e00f";
}
.icon-gear:before {
  content: "\e056";
}
.icon-programming:before {
  content: "\63";
}
.icon-human-computer:before {
  content: "\6a";
}
.icon-design:before {
  content: "\62";
}
.icon-badge:before {
  content: "\6e";
}
.icon-turtle:before {
  content: "\75";
}
.icon-soccer:before {
  content: "\64";
}
.icon-person:before {
  content: "\71";
}
.icon-paint-bucket:before {
  content: "\76";
}
.icon-backgrounds:before {
  content: "\7a";
}
.icon-quote:before {
  content: "\41";
}
.icon-pencil:before {
  content: "\4f";
}
.icon-mail-solid:before {
  content: "\51";
}
.icon-earrings:before {
  content: "\5a";
}
.icon-timer:before {
  content: "\22";
}
.icon-mustaches:before {
  content: "\23";
}
.icon-sunglasses:before {
  content: "\3f";
}
.icon-legs:before {
  content: "\5d";
}
.icon-actions:before {
  content: "\e01d";
}
.icon-table-tennis:before {
  content: "\e023";
}
.icon-upload-photo:before {
  content: "\4a";
}
.icon-boxing:before {
  content: "\e027";
}
.icon-birthday-two:before {
  content: "\e035";
}
.icon-chrome:before {
  content: "\e036";
}
.icon-puzzle:before {
  content: "\e03d";
}
.icon-movie:before {
  content: "\e03b";
}
.icon-weightlifting:before {
  content: "\e047";
}
.icon-speedway:before {
  content: "\e04d";
}
.icon-rocket:before {
  content: "\e05f";
}
.icon-ipad-iphone:before {
  content: "\66";
}
.icon-brackets:before {
  content: "\74";
}
.icon-awesome-meter:before {
  content: "\25";
}
.icon-team-icon:before {
  content: "\3b";
}
.icon-guess-who:before {
  content: "\5b";
}
.icon-animation-icon:before {
  content: "\e006";
}
.icon-music-icon:before {
  content: "\e058";
}
.icon-link:before {
  content: "\e043";
}
.icon-google-classroom:before {
  content: "\e044";
}
.icon-add-student:before {
  content: "\e05d";
}
.icon-student:before {
  content: "\e06f";
}
.icon-teacher:before {
  content: "\e071";
}
.icon-unlock:before {
  content: "\e074";
}
.icon-lock:before {
  content: "\e075";
}

